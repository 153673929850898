.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.hero {
  height: 100vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  text-align: center;
  color: white;
  text-shadow: 1px 1px 2px black;
  align-items: center;
  justify-content: center;
  font-size: 10vw;
  padding-top: 25vh;
}


@media screen and (max-width: 992px) {
    .hero {
        background-attachment: scroll;
        object-position: 50% 0%;
        height: 50vh;
    }
}

.about-header{
  font-size: 8vw;
  padding-top: 30px;
  padding-left: 20px;
}

.menuTile{
  background-color: lightgray;
}

.heroSubtext{
  font-size: 5vw;
}
.menu-background{
  height: fit-content;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media screen and (max-width: 992px) {
    .menu-background {
        background-attachment: scroll;
    }
}

.bg-img1 {
      background-image: url('https://cricchicookin-will123.s3.amazonaws.com/public/heroImage.jpg');
}
.bg-img2 {
      background-image: url('https://cricchicookin-will123.s3.amazonaws.com/public/EggsBenny.jpg');
}
.bg-img3 {
      background-image: url('https://cricchicookin-will123.s3.amazonaws.com/public/menuBackground.jpg');
}
.bg-img4 {
      background-image: url('https://cricchicookin-will123.s3.amazonaws.com/public/lamb.jpg');
}

.heroImg {
  position: absolute;
  top: -300px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
